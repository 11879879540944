import logo from './logo.svg';
import './App.css';
import { BrowserRouter,Routes,Route } from 'react-router-dom'
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';



function App() {
  return (
    <BrowserRouter>
    <div id="mad-page-wrapper" class="mad-page-wrapper">
    <Header/>
    
    <Routes>
    <Route path="/" element={<Home/>}/>
    {/* <Footer/> */}
   
    </Routes>
   
    </div>
    </BrowserRouter>
  );
}

export default App;
