import React from 'react';
import { useState,useEffect } from 'react';

const Alert = (props) => {
    if(props.showAlert){
        return (
        
            <div className="alert alert-success alert-dismissible fade show text-center">
                <img src="/images/success.png" alt="" /><br />
                {/* <strong>{props.alertData.type}</strong> */}
                <p>{props.alertData.msg}<br/>{props.alertData.type}</p>
                {/* <p>{props.alertData.type}</p> */}
                <button type="button" className="btn-close" onClick={()=>props.setShowAlert(false)}></button>
            </div>
    
        )
    }
    return <></>
    
}

export default Alert
