import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <>

  {/*================ Header ================*/}
  <header
    id="mad-header"
    className="mad-header header-2 mad-header--transparent mad-header--transparent-single"
  >
    <div className="mad-middle-header mad-header-section--sticky-xl">
      <div className="container-fluid">
        <div className="mad-header-items">
          <div className="mad-header-item">
            <div className="mad-actions">
              <div className="mad-col">
                {/* <button id="mad-nav-btn" className="mad-nav-btn">
                  <span className="line line-top" />
                  <span className="line line-center" />
                  <span className="line line-bottom" />
                </button> */}
              </div>
              <div className="mad-col">
                <div className="mad-social-icons style-2 size-small">
                  <ul className='icons'>
                    {/* <li>
                      <Link to="https://www.facebook.com/monkeysan">
                        <i className="fab fa-facebook-f" />
                      </Link>
                    </li> */}
                    <li>
                      <Link to="https://www.instagram.com/wambaafrica" target='new_blank'>
                        <i className="fab fa-instagram" />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://twitter.com/WambaAfrica" target='new_blank'>
                        <i className="fab fa-twitter" />
                      </Link>
                    </li>
                    {/* <li>
                      <a href="https://www.youtube.com/channel/UCHLY0iBPh03oLba9Hc0ABMg">
                        <i className="fab fa-youtube" />
                      </a>
                    </li> */}
                    <li>
                      <Link to="https://www.linkedin.com/in/maryanne-wamba-3533325b/" target='new_blank'>
                        <i className="fab fa-linkedin-in" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="mad-col">
                <a href="#" data-arctic-modal="#search-modal">
                  <i className="material-icons">search</i>
                </a>
              </div> */}
            </div>
          </div>
          <div className="mad-header-item">
            
            <Link to="/" className="logo">
              <img src="images/logo1.png" alt="" style={{ maxHeight: "100px" }} width="100%"/>
            </Link>
          </div>
          <div className="mad-header-item a1">
            <div className="mad-actions">
              <div className="mad-col">
                <div className="mad-our-info">
                  <div className="mad-our-info">
                    <div className="mad-info">
                    {/* <img src="psychologist_icons_svg/email.svg" alt="" class="svg" /> */}
                      {/* <span>
                        <b><a href="mailto: info@wambaafrica.com"> info@wambaafrica.com</a></b>
                      </span> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mad-col">
                <a href="#contact" className="btn scroll-to-section">
                BOOK A SESSION
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  {/*================ End of Header ================*/}
</>

  )
}

export default Header
